import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';

import { BASE_URL } from '../../../../env/Baseurl';
import ErrorLogging from '../../../../services/error-logs';
import RefreshToken from '../../../../services/refresh-token';
import '../../../../styles/adminlte.min.css';
import '../../../../styles/dashboard-style.css';
import Header from '../../../global/Header';
import Sidebar from '../../../global/Sidebar';
import Footer from '../../../global/Footer';
import { GetAllTyrannyShouldsQuestions } from '../../../../services/part5/tyranny-of-the-shoulds-questions';
import { myprintFunction } from '../../../../actions/NormalPrintFunction';
import FooterOfPrint from '../../../global/FootorOfPrint';
import { useTranslation } from 'react-i18next';


const Part5Fourth = () => {

    const { t } = useTranslation(["partFiveFourth", "actions"])

    const [tyrannyShouldsQuestions, errorMessage] = GetAllTyrannyShouldsQuestions();        // Get Tyranny Should Questions from Api

    /*******   States *******/

    const [disabled, setDisabled] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [postErrorMessage, setErrorMessage] = useState('');
    const [textBoxValidation, setTextboxValidation] = useState({
        id: "",
        errorMessage: "",
        disabledMessage: ""
    });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    let handleClose = () => setShow(false);
    const [state, setState] = useState(
        [
            {
                question_id: "",
                disadvantage_userresponce: "",
                advantage_userresponce: ""
            },
            {
                question_id: "",
                disadvantage_userresponce: "",
                advantage_userresponce: ""
            },
            {
                question_id: "",
                disadvantage_userresponce: "",
                advantage_userresponce: ""
            }
        ]

    );

    // error modal
    const [showError, setShowError] = useState(false);
    const handle = () => setShowError(true);
    let close = () => setShowError(false);

    /*******   Helper Functions  *******/

    let navigate = useNavigate();

    const isEmpty = state.filter(function (o) {

        var values = Object.values(o);
        var arr2 = [""];         //array of values you don't want.
        if (arr2.some(function (val) { return values.indexOf(val) >= 0; }))      //checks if atleast one value of arr2 is in values.
            return false;
        else
            return true;

    });

    const specialCharactersRegex = /^[^a-zA-Z0-9]+$/;

    const handleChange = (inputId, arrayIndex) => (e) => {
        const { value } = e.target;

        const { name } = e.target;

        const newState = state.map((obj, index) => {
            if (index === arrayIndex) {
                if (name === "advantage_userresponce") {
                    return { ...obj, question_id: inputId, advantage_userresponce: value };
                } else {
                    return { ...obj, question_id: inputId, disadvantage_userresponce: value };
                }
            }
            return obj;
        });

        setState(newState);


        if (value !== "" || value === "") {
            specialCharactersRegex.test(
                value) ?
                setTextboxValidation({
                    id: inputId,
                    errorMessage: t('partFiveFourth:textBoxValidation.validation1'),
                    disabledMessage: t('partFiveFourth:textBoxValidation.validation2')
                })
                :
                setTextboxValidation({
                    id: inputId,
                    errorMessage: "",
                    disabledMessage: t('partFiveFourth:textBoxValidation.validation3')
                })
        };

    };


    const postSubmission = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state,
                "saveasdraft": "false"

            });
            const response = await axios.post(BASE_URL + `advantage_disadvantage_of_should_statement_usersubmit/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,

                },
            })
            setSuccessMessage(response.data);
            if (state.length === 3) {
                navigate('/part_5/should_sample_answers', { replace: true })
            } else {
                alert('Please fill all the fields.');
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                postSubmission();
            }
            else {
                setErrorMessage('Please check Create Questionnaire Api');
                ErrorLogging('HTTP POST /createQuestionnairs_type/userId' + error);
                handle();
            }
        }
    };


    const saveDraft = async () => {

        const userInfo = localStorage.getItem('user');
        const user = JSON.parse(userInfo);

        try {
            const params = JSON.stringify({
                "data": state,
                "saveasdraft": "true"

            });
            const response = await axios.post(BASE_URL + `advantage_disadvantage_of_should_statement_usersubmit/${user.userInfo._id}`, params, {
                "headers": {
                    "Authorization": `Bearer ${user.token}`,
                    "content-type": "application/json",
                    "languageCode": user.userInfo.languageCode,
                },
            })
            if (response.data.status === true) {
                setSuccessMessage('Saved As Draft');
                handleShow();
            }
        } catch (error) {
            if (error.response.status === 401) {
                RefreshToken(user.userInfo._id)
                saveDraft();
            }
            else {
                setErrorMessage('Please check Create Questionnaire Api');
                ErrorLogging('HTTP POST /createQuestionnairs_type/userId' + error);
                handle();
            }
        }

    };

    const nextPage = () => {
        navigate('/part_5/should_sample_answers', { replace: true })
    };

    const previousPage = () => {

        navigate(`/part_5/what_do_my_shoulds_do_for_me`, { replace: true })

    };
    // close modal on its own

    setTimeout(handleClose, 1000);

    /*******   Hooks  *******/

    useEffect(() => {

        const arr1 = Array.from(state.filter((e) => specialCharactersRegex.test(e.disadvantage_userresponce)));
        const arr2 = Array.from(state.filter((e) => specialCharactersRegex.test(e.advantage_userresponce)));

        if (tyrannyShouldsQuestions !== undefined && tyrannyShouldsQuestions.length !== 0) {

            if (isEmpty.length === 3 && arr1.length === 0 && arr2.length === 0) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }, [state]);

    /*******   Render   *******/

    return (
        <>
            <div id="printAble"></div>
            <div id="mainContainer" className="dashboardContent">
                <Helmet>
                    <title>{t('partFiveFourth:helemt')}</title>
                </Helmet>
                <div>
                    {/* <Header /> */}
                    <Header targeted_print_component_id={"main"} myprintFunction={myprintFunction} />
                </div>
                <div className="row">
                    <div className="col-md-2  position-static pl-0">
                        <div className="leftside">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-md-12 content-widthsidebar content-wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div id="main">
                                    <div className="card-body">
                                        <div className="title mb-3">
                                        {t('partFiveFourth:title')}
                                        </div>
                                        {
                                            errorMessage ? (<div className="container mt-5">
                                                <div className='row justify-content-center'>
                                                    <div className="alert alert-danger text-center" role="alert">
                                                        {errorMessage}
                                                    </div>
                                                </div>
                                            </div>)
                                                :
                                                tyrannyShouldsQuestions !== undefined && tyrannyShouldsQuestions.length !== 0 ? (
                                                    <div className="content-wrap">
                                                        <div>
                                                            {tyrannyShouldsQuestions.data.map((item, index) => {
                                                                const card = (
                                                                    <div className="left-content" key={index}>
                                                                        <div className='row ipForms'>
                                                                            <div className='col-lg-12 col-sm-12 col-xs-12 order-md-1'>
                                                                                <p className="questions">
                                                                                </p>
                                                                                <p className='text-center mt-4'>
                                                                                    <b className='topQuestion title subtitle-text'>{item.question_id.question}</b>
                                                                                </p>
                                                                                <p className="help-text redText mt-3 mb-3">
                                                                                {t('partFiveFourth:content.line1')}
                                                                                </p>
                                                                                <p className='help-text redText mt-3 mb-3'>
                                                                                {t('partFiveFourth:content.line2')}
                                                                                </p>
                                                                                <div className="textbox">
                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        readOnly={item.disable_status}
                                                                                        id="ControlTextarea"
                                                                                        rows="10"
                                                                                        placeholder= {t('partFiveFourth:placeholders')}
                                                                                        defaultValue={item.advantage_userresponce}
                                                                                        name="advantage_userresponce"
                                                                                        onChange={handleChange(item.question_id._id, index)}
                                                                                    />
                                                                                </div>
                                                                                {textBoxValidation.id === item.question_id._id && (
                                                                                    <small className="text-danger">
                                                                                        {textBoxValidation.errorMessage}
                                                                                    </small>
                                                                                )}
                                                                                <p className='help-text redText mt-3 mb-3'>
                                                                                {t('partFiveFourth:content.line3')}
                                                                                </p>
                                                                                <div className="textbox">
                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        readOnly={item.disable_status}
                                                                                        id="ControlTextarea"
                                                                                        rows="10"
                                                                                        placeholder={t('partFiveFourth:placeholders')}
                                                                                        defaultValue={item.disadvantage_userresponce}
                                                                                        name="disadvantage_userresponce"
                                                                                        onChange={handleChange(item.question_id._id, index)}
                                                                                    />
                                                                                </div>
                                                                                {textBoxValidation.id === item.question_id._id && (
                                                                                    <small className="text-danger">
                                                                                        {textBoxValidation.errorMessage}
                                                                                    </small>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                return card;
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                                    :
                                                    <div className="loader-icon">
                                                        <TailSpin color="#4f5962" height={50} width={50} />
                                                    </div>
                                        }
                                    </div>
                                    {/* <div>
                                        <FooterOfPrint/>
                                    </div> */}
                                </div>

                                <div className='row'>
                                    <div className='previous1 col-6'>

                                        <Button
                                            className="user-reponse-submit btn btn-primary link-sty mt-4"
                                            onClick={previousPage}

                                        >
                                           {t('actions:previous')}
                                        </Button>
                                    </div>
                                    <div className='next col-6'>
                                        {tyrannyShouldsQuestions !== undefined && tyrannyShouldsQuestions.length !== 0 && tyrannyShouldsQuestions.global_disable_status === false ? (
                                            <>
                                                <Button
                                                    className="user-reponse-submit save-draft-btn mt-4"
                                                    onClick={saveDraft}
                                                >
                                                    {t('actions:save')}
                                                </Button>
                                            </>
                                        ) :
                                            <>
                                            </>
                                        }
                                        {tyrannyShouldsQuestions !== undefined && tyrannyShouldsQuestions.length !== 0 && tyrannyShouldsQuestions.global_disable_status === false ? (
                                            <>
                                                <span data-tip={disabled === true ? textBoxValidation.disabledMessage : ""} data-for='toolTip'>
                                                    <Button
                                                        className="user-reponse-submit btn btn-primary link-sty mt-4"
                                                        onClick={postSubmission}
                                                        disabled={disabled}
                                                    >
                                                       {t('actions:continue')}
                                                    </Button>
                                                </span>
                                                <ReactTooltip id="toolTip" />
                                            </>
                                        ) :
                                            <>
                                                <Button
                                                    className="user-reponse-submit btn btn-primary link-sty mt-4"
                                                    onClick={nextPage}
                                                >
                                                    {t('actions:continue')}
                                                </Button>
                                            </>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                        {successMessage ?
                            <Modal.Body>{successMessage}</Modal.Body>
                            :
                            <Modal.Body>{errorMessage}</Modal.Body>
                        }
                    </Modal>
                    {/* error message modal */}

                    <Modal show={showError} >
                        <Modal.Body>{postErrorMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                            {t('actions:close')}
                            </Button>

                        </Modal.Footer>
                    </Modal>

                    <div className="col-md-12 dashboard-footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Part5Fourth;