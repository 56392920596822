// import React, { useRef, useState, useEffect, forwardRef } from "react";
// import { Helmet } from "react-helmet";
// import "../../../../styles/adminlte.min.css";
// import "../../../../styles/dashboard-style.css";
// import Header from "../../../global/Header";
// import Sidebar from "../../../global/Sidebar";
// import Footer from "../../../global/Footer";
// import SendProgramCompleteEmail from "../../../../services/post-treatment/send-program-complete-email";
// import { CompletePageUserResult } from "../../../../services/post-treatment/complete-page-user-result";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Bar } from "react-chartjs-2";
// // for eBook
// import eBook from "../../../../Ebook/eBook-iGlebe-July2021.pdf";
// import eBookEs from "../../../../Ebook/iGlebe_eBook_Castilian_Spanish.pdf";
// import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router";

// var htmlbody = "<html><body style='width:100% !important;' >";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const CompleteProgram = forwardRef((props, ref) => {
//   const loc = useLocation();

//   const { t } = useTranslation(["posttreatmentCompleteProgram", "part7completionmail"]);

//   const userInfo = localStorage.getItem("user");
//   const user = JSON.parse(userInfo);
//   const username = user?.userInfo?.user_name;
//   const languageCode = user.userInfo.languageCode;
//   const [emailSent, setEmailSent] = useState(false);

//   const [userResult, errorMessage] = CompletePageUserResult(); // Get User Result from API

//   const emailHtml = () => {
//     htmlbody += `<div className="left-content" style="display: block;min-width: 600px;">
//     <p>${t('part7completionmail:line1', { lng: languageCode })} ${username},</p>
//     <p>${t('part7completionmail:line2', { lng: languageCode })}</p>
//     <p>${t('part7completionmail:line3', { lng: languageCode })}</p>`
//     if (userResult !== undefined && userResult.length !== 0) {
//       {
//         userResult.result.map((item, index) => {
//           htmlbody += `
//           <p style="color: #ff2305;font-weight:bold;font-family: Helvetica; margin-top:20px">${item.name} </p><div><div style="display:flex;align-items: baseline"><p style="margin-right: 2%">Before:</p><div style="height:10px;width: 5%;margin-right: 20px;display: flex;height: 10px;overflow: hidden;line-height: 0; border-radius:10%;font-size: .75rem;background-color: #e9ecef"><div role="progressbar" style=" width: ${item.before_width};background: #ff0000" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
//                              </div></div><span>${item.Before} </span></div></div><div><div style="display:flex;align-items: baseline"><p style="margin-right: 2%">After:</p><div style="height:10px;width: 5%;margin-right: 20px;display: flex;height: 10px;overflow: hidden;line-height: 0; border-radius:10%;font-size: .75rem;background-color: #e9ecef"><div role="progressbar" style=" width: ${item.after_width} ;background: #00FF00" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div></div><span> ${item.After}</span></div></div>
// `;
//         });
//       }}
//       htmlbody += `<p>${t('part7completionmail:line4', { lng: languageCode })}</p>

// <p>${t('part7completionmail:line5', { lng: languageCode })} <a href='mailto:asrc.internetprograms@uts.edu.au' style="color:blue;text-decoration:underline; text-decoration-color:blue;">${t('part7completionmail:link', { lng: languageCode })} </a> ${t('part7completionmail:line6', { lng: languageCode })}</p>

// <p>${t('part7completionmail:line7', { lng: languageCode })}</p>

// <p>${t('part7completionmail:line8', { lng: languageCode })}
// <br/>
// ${t('part7completionmail:line9', { lng: languageCode })}</p>

// <p>${t('part7completionmail:line10', { lng: languageCode })}
// <br/>
// ${t('part7completionmail:line11', { lng: languageCode })}
// <br/>
// ${t('part7completionmail:line12', { lng: languageCode })}</p>

// <p style="font-style:italic;">${t('part7completionmail:line13', { lng: languageCode })}</p>
// </div>
//       </body></html>`;
//       return htmlbody;
//   };

//   useEffect(() => {
//     emailHtml();
//   }, [userResult]);

//   useEffect(() => {
//     console.log(userResult, "ite data for graph");
//   }, [userResult]);

//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//       title: {
//         display: false,
//         text: "User ",
//       },
//     },
//   };

//   let labels = "";

//   if (user?.userInfo?.languageCode === "en") {
//     labels = [
//       "Anxiety",
//       "Stress",
//       "Depression",
//       "DASS",
//       "FNE",
//       "UTBAS",
//       "Avoidance",
//     ];
//   } else if (user?.userInfo?.languageCode === "es") {
//     labels = [
//       "Ansiedad",
//       "Estrés",
//       "Depresión",
//       "DASS",
//       "FNE",
//       "UTBAS",
//       "Evitación",
//     ];
//   }
//   else {
//     labels = [
//       "Ansiedad",
//       "Estrés",
//       "Depresión",
//       "DASS",
//       "FNE",
//       "UTBAS",
//       "Evitación",
//     ];
//   }

//   const data = {
//     labels,
//     datasets: [
//       {
//         label: "Before",
//         data: userResult.before_data,
//         // backgroundColor: '#FF2305',
//         backgroundColor: "#8b0000",
//       },
//       {
//         label: "After",
//         data: userResult.after_data,
//         backgroundColor: "#0F4BEB",
//       },
//     ],
//   };

//   /*******  Hooks  *******/

//   // useEffect(() => {
//   //   const timeout = setTimeout(() => {
//   //     SendProgramCompleteEmail(htmlbody); // Sending module completion email
//   //   }, 6000);
//   // }, []);

//   useEffect(() => {
//     if (!emailSent) {
//       const timeout = setTimeout(() => {
//         SendProgramCompleteEmail(htmlbody); // Sending module completion email
//         setEmailSent(true);
//       }, 6000);
  
//       return () => clearTimeout(timeout); // Cleanup
//     }
//   }, [emailSent, htmlbody]);

//   const currentUser = `${t("posttreatmentCompleteProgram:user")} ${user.userInfo.user_name
//     }.`;
//   const content = (
//     <p className="TextsLast">
//       {t("posttreatmentCompleteProgram:content.line1")}
//       <br></br>
//       {t("posttreatmentCompleteProgram:content.line2")}
//       <br></br>
//       {t("posttreatmentCompleteProgram:content.line3")}
//       <br></br>
//       {t("posttreatmentCompleteProgram:content.line4")}.<br></br>
//       {t("posttreatmentCompleteProgram:content.line5")}
//       <br></br>
//       Fjóla and Ross
//     </p>
//   );

//   const Title = t("posttreatmentCompleteProgram:title");
//   const pageTitle = t("posttreatmentCompleteProgram:pageTitle");
//   /*******  Render  *******/

//   // download copy

//   const downloadCopy = (e) => {
//     e.preventDefault();
//     if (loc.pathname === "/part_7/complete") {
//       if (user?.userInfo?.languageCode === "en") {
//         window.open(eBook, "_blank");
//       } else {
//         window.open(eBookEs, "_blank");
//       }
//     } else return;
//   };

//   return (
//     <>
//       <div className="dashboardContent">
//         <Helmet>
//           <title>{t("posttreatmentCompleteProgram:helmet")}</title>
//         </Helmet>
//         <div>
//           {/* <Header /> */}
//           <Header targeted_print_component_id={"bargraph"} />
//         </div>

//         <div className="row col-md-12">
//           <div className="col-md-2  position-static pl-0">
//             <div className="leftside">
//               <Sidebar />
//             </div>
//           </div>

//           <div className="col-md-10 content-widthsidebar content-wrapper">
//             <div id="bargraph">
//               <div className="row header-titlewith-text m-0">
//                 <div className="title col-md-6 p-0">{pageTitle}</div>
//                 <div className="col-md-6 text-right p-0">
//                   <span>
//                     {" "}
//                     {t("posttreatmentCompleteProgram:content.line6")}
//                   </span>{" "}
//                   / {Title}
//                 </div>
//               </div>
//               <div className="content-topheads-text">
//                 <div className="TextsLast p-3 ">
//                   {currentUser}
//                   <br />
//                   {content}
//                 </div>
//               </div>

//               <div id="bargraph" className="marginBotoom mt-3 card mb-4">
//                 <div
//                   className="card-title p-3"
//                   style={{ borderBottom: "1px solid #8080802e" }}
//                   id="card1"
//                 >
//                   {t("posttreatmentCompleteProgram:content.line7")}
//                 </div>
//                 <div className="alignCenter p-3">
//                   <div className="lastBar">
//                     <Bar options={options} data={data} />
//                   </div>
//                   <div className="alignLeft">
//                     <p className="ptext">
//                       {t("posttreatmentCompleteProgram:content.line8")}
//                     </p>
//                   </div>
//                   <button
//                     type="button"
//                     className="downloadButton"
//                     onClick={downloadCopy}
//                   >
//                     <i
//                       className="fas fa-book-open"
//                       style={{ paddingRight: "4px", paddingLeft: "7px" }}
//                     ></i>
//                     {t("posttreatmentCompleteProgram:content.line9")}
//                   </button>
//                 </div>
//                 {/* <div>
//                   <FooterOfPrint />
//                 </div> */}
//               </div>
//             </div>
//           </div>
//           <div className="col-md-12 dashboard-footer">
//             <Footer />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// });
// export default CompleteProgram;


import React, { useRef, useState, useEffect, forwardRef } from "react";
import { Helmet } from "react-helmet";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import SendProgramCompleteEmail from "../../../../services/post-treatment/send-program-complete-email";
import { CompletePageUserResult } from "../../../../services/post-treatment/complete-page-user-result";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// for eBook
import eBook from "../../../../Ebook/eBook-iGlebe-July2021.pdf";
import eBookEs from "../../../../Ebook/iGlebe_eBook_Castilian_Spanish.pdf";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

var htmlbody = "<html><body>";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CompleteProgram = forwardRef((props, ref) => {
  const loc = useLocation();

  const { t } = useTranslation(["posttreatmentCompleteProgram"]);

  const userInfo = localStorage.getItem("user");
  const user = JSON.parse(userInfo);

  const [userResult, errorMessage] = CompletePageUserResult(); // Get User Result from API

  const emailHtml = () => {
    if (userResult !== undefined && userResult.length !== 0) {
      {
        userResult.result.map((item, index) => {
          htmlbody += `<div className="left-content"><p style="color: #ff2305;font-weight:bold;font-family: Helvetica; margin-top:20px">${item.name} </p><div><div style="display:flex;align-items: baseline"><p style="margin-right: 2%">Before:</p><div style="height:10px;width: 5%;margin-right: 20px;display: flex;height: 10px;overflow: hidden;line-height: 0; border-radius:10%;font-size: .75rem;background-color: #e9ecef"><div role="progressbar" style=" width: ${item.before_width};background: #ff0000" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                             </div></div><span>${item.Before} </span></div></div><div><div style="display:flex;align-items: baseline"><p style="margin-right: 2%">After:</p><div style="height:10px;width: 5%;margin-right: 20px;display: flex;height: 10px;overflow: hidden;line-height: 0; border-radius:10%;font-size: .75rem;background-color: #e9ecef"><div role="progressbar" style=" width: ${item.after_width} ;background: #00FF00" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div></div><span> ${item.After}</span></div></div>
                             </div>`;
        });
      }
      htmlbody += "</body></html>";
    }
  };

  useEffect(() => {
    emailHtml();
  }, [userResult]);

  useEffect(() => {
    console.log(userResult, "ite data for graph");
  }, [userResult]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "User ",
      },
    },
  };

  let labels = "";

  if (user?.userInfo?.languageCode == "en") {
    labels = [
      "Anxiety",
      "Stress",
      "Depression",
      // "DASS",
      "FNE",
      "UTBAS",
      "Avoidance",
    ];
  } else {
    labels = [
      "Ansiedad",
      "Estrés",
      "Depresión",
      // "DASS",
      "FNE",
      "UTBAS",
      "Evitación",
    ];
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Before",
        data: userResult.before_data,
        // backgroundColor: '#FF2305',
        backgroundColor: "#8b0000",
      },
      {
        label: "After",
        data: userResult.after_data,
        backgroundColor: "#0F4BEB",
      },
    ],
  };

  /*******  Hooks  *******/

  useEffect(() => {
    const timeout = setTimeout(() => {
      SendProgramCompleteEmail(htmlbody); // Sending module completion email
    }, 6000);
  }, []);

  const currentUser = `${t("posttreatmentCompleteProgram:user")} ${
    user.userInfo.user_name
  }.`;
  const content = (
    <p className="TextsLast">
      {t("posttreatmentCompleteProgram:content.line1")}
      <br></br>
      {t("posttreatmentCompleteProgram:content.line2")}
      <br></br>
      {t("posttreatmentCompleteProgram:content.line3")}
      <br></br>
      {t("posttreatmentCompleteProgram:content.line4")}.<br></br>
      {t("posttreatmentCompleteProgram:content.line5")}
      <br></br>
      Fjóla and Ross
    </p>
  );

  const Title = t("posttreatmentCompleteProgram:title");
  const pageTitle = t("posttreatmentCompleteProgram:pageTitle");
  /*******  Render  *******/

  // download copy

  const downloadCopy = (e) => {
    e.preventDefault();
    if (loc.pathname === "/part_7/complete") {
      if (user?.userInfo?.languageCode == "en") {
        window.open(eBook, "_blank");
      } else {
        window.open(eBookEs, "_blank");
      }
    } else return;
  };

  return (
    <>
      <div className="dashboardContent">
        <Helmet>
          <title>{t("posttreatmentCompleteProgram:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header /> */}
          <Header targeted_print_component_id={"bargraph"} />
        </div>

        <div className="row">
          <div className="position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>

          <div className="col-md-12 content-widthsidebar">
            <div id="bargraph">
              <div className="row header-titlewith-text m-0">
                <div className="title col-md-6 p-0">{pageTitle}</div>
                <div className="col-md-6 text-right p-0">
                  <span>
                    {" "}
                    {t("posttreatmentCompleteProgram:content.line6")}
                  </span>{" "}
                  / {Title}
                </div>
              </div>
              <div className="content-topheads-text">
                <div className="TextsLast p-3 ">
                  {currentUser}
                  <br />
                  {content}
                </div>
              </div>

              <div id="bargraph" className="marginBotoom mt-3 card mb-4">
                <div
                  className="card-title p-3"
                  style={{ borderBottom: "1px solid #8080802e" }}
                  id="card1"
                >
                  {t("posttreatmentCompleteProgram:content.line7")}
                </div>
                <div className="alignCenter p-3">
                  <div className="lastBar">
                    <Bar options={options} data={data} />
                  </div>
                  <div className="alignLeft">
                    <p className="ptext">
                      {t("posttreatmentCompleteProgram:content.line8")}
                    </p>
                  </div>
                  <button
                    type="button"
                    className="downloadButton"
                    onClick={downloadCopy}
                  >
                    <i
                      className="fas fa-book-open"
                      style={{ paddingRight: "4px", paddingLeft: "7px" }}
                    ></i>
                    {t("posttreatmentCompleteProgram:content.line9")}
                  </button>
                </div>
                {/* <div>
                  <FooterOfPrint />
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
});
export default CompleteProgram;