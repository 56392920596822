import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { TailSpin } from "react-loader-spinner";

import axios from "axios";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../../../../env/Baseurl";
import ErrorLogging from "../../../../services/error-logs";
import RefreshToken from "../../../../services/refresh-token";
import "../../../../styles/adminlte.min.css";
import "../../../../styles/dashboard-style.css";
import Header from "../../../global/Header";
import Sidebar from "../../../global/Sidebar";
import Footer from "../../../global/Footer";
import SelectOptions from "../../../../constants/part1/ChecklistCognitiveErrors";
import { GetChecklistErrorsResponse } from "../../../../services/part1/get-checklist-errors-response";
import FooterOfPrint from "../../../global/FootorOfPrint";
import { myprintFunction } from "../../../../actions/NormalPrintFunction";
import { useTranslation } from "react-i18next";
const ChecklistCognitiveErrors = () => {
  const { t } = useTranslation(["actions", "partOneChecklistCognitiveErrors"]);

  const {
    selectOptions1,
    selectOptions2,
    selectOptions3,
    selectOptions4,
    selectOptions5,
    selectOptions6,
    selectOptions7,
    selectOptions8,
    selectOptions9,
    selectOptions10,
    selectOptions11,
  } = SelectOptions();

  const [checklistErrorsResponse, responseErrorMessage] =
    GetChecklistErrorsResponse();

  /*******   States *******/

  const [state, setState] = useState({ data: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [options, setOptions] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
    seven: "",
    eight: "",
    nine: "",
    ten: "",
    eleven: "",
  });

  /*******   Helper Functions  *******/

  let navigate = useNavigate();

  const selectHandler = (name, value, ques, label) => {
    setOptions({ ...options, [name]: value });

    if (state.data.some((item) => item.question === ques)) {
      // Checking Question ID in state on change

      let updatedData = state.data.map((item) => {
        if (item.question === ques) {
          return {
            ...item,
            question: ques,
            userResponce: label,
            quesindex: name,
          }; // Updating user selection in state
        }
        return item;
      });

      setState({ data: updatedData });
    } else {
      setState((state) => ({
        data: [
          ...state.data,
          { question: ques, userResponce: label, quesindex: name },
        ], // Adding new question response in state
      }));
    }
  };

  const postSubmission = async () => {
    const userInfo = localStorage.getItem("user");
    const user = JSON.parse(userInfo);

    try {
      const params = JSON.stringify({
        data: state.data,
      });
      const response = await axios.post(
        BASE_URL + `checklist_of_error_create/${user.userInfo._id}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "content-type": "application/json",
            "languageCode": user.userInfo.languageCode,
          },
        }
      );
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        RefreshToken(user.userInfo._id);
        postSubmission();
      } else {
        setErrorMessage(t("partOneChecklistCognitiveErrors:setErrorMessage"));
        ErrorLogging("HTTP POST /checklist_of_error_create/userId" + error);
      }
    }

    navigate("/part_1/thinking_exercise_3", { replace: true });
  };

  const nextPage = () => {
    navigate("/part_1/thinking_exercise_3", { replace: true });
  };

  const previousPage = () => {
    navigate(`/part_1/causal_thoughts`, { replace: true });
  };
  /*******   Page Params  *******/

  const title = t("partOneChecklistCognitiveErrors:title");

  const content = `<div>${t(
    "partOneChecklistCognitiveErrors:description"
  )}</div>`;

  /*******   Hooks  *******/

  useEffect(() => {
    let isEmpty = Object.values(options).every((x) => x !== ""); // Checking if there is empty response
    setDisabled(!isEmpty);
  }, [options]);

  /*******   Render  *******/

  return (
    <>
      <div id="printAble"></div>
      <div id="mainContainer" className="dashboardContent">
        <Helmet>
          <title>{t("partOneChecklistCognitiveErrors:helmet")}</title>
        </Helmet>
        <div>
          {/* <Header/> */}
          <Header
            targeted_print_component_id={"main"}
            myprintFunction={myprintFunction}
          />
        </div>
        <div className="row">
          <div className="col-md-2  position-static pl-0">
            <div className="leftside">
              <Sidebar />
            </div>
          </div>
          <div className="col-md-12 content-widthsidebar content-wrapper">
            <div className="card">
              <div className="card-body">
                <div id="main">
                  <div className="card-body">
                    <div className="title mb-3">{title}</div>
                    <div className="welcome-content">
                      <div>
                        <p>
                          {t("partOneChecklistCognitiveErrors:content.line1")}
                        </p>
                        <p className="redText">
                          {t("partOneChecklistCognitiveErrors:content.line2")}
                        </p>
                        <h4 className="redText">
                          {t("partOneChecklistCognitiveErrors:content.line3")}
                        </h4>
                        <p>
                          1.{" "}
                          <span className="redText">
                            {t("partOneChecklistCognitiveErrors:content.line4")}
                          </span>{" "}
                          {t("partOneChecklistCognitiveErrors:content.line5")}
                        </p>
                        <p>
                          2.{" "}
                          <span className="redText">
                            {t("partOneChecklistCognitiveErrors:content.line6")}
                          </span>{" "}
                          {t("partOneChecklistCognitiveErrors:content.line7")}
                        </p>
                        <p>
                          3.{" "}
                          <span className="redText">
                            {t("partOneChecklistCognitiveErrors:content.line8")}
                          </span>{" "}
                          {t("partOneChecklistCognitiveErrors:content.line9")}
                        </p>
                        <p>
                          4.{" "}
                          <span className="redText">
                            {t(
                              "partOneChecklistCognitiveErrors:content.line10"
                            )}
                          </span>{" "}
                          {t("partOneChecklistCognitiveErrors:content.line11")}
                        </p>
                        <p>
                          5.{" "}
                          <span>
                            {t(
                              "partOneChecklistCognitiveErrors:content.line12"
                            )}
                          </span>
                        </p>
                        <ul className="sub-list">
                          <li>
                            <p>
                              {t(
                                "partOneChecklistCognitiveErrors:content.line13.tag1"
                              )}{" "}
                              <span className="redText">
                                {t(
                                  "partOneChecklistCognitiveErrors:content.line13.subLine1"
                                )}
                              </span>{" "}
                              {t(
                                "partOneChecklistCognitiveErrors:content.line13.subLine2"
                              )}
                            </p>
                          </li>
                          <li>
                            <p>
                              {t(
                                "partOneChecklistCognitiveErrors:content.line13.tag2"
                              )}{" "}
                              <span className="redText">
                                {" "}
                                {t(
                                  "partOneChecklistCognitiveErrors:content.line13.subLine3"
                                )}
                              </span>{" "}
                              {t(
                                "partOneChecklistCognitiveErrors:content.line13.subLine4"
                              )}
                            </p>
                          </li>
                        </ul>
                        <p>
                          6.{" "}
                          <span className="redText">
                            {t(
                              "partOneChecklistCognitiveErrors:content.line14"
                            )}
                          </span>{" "}
                          {t("partOneChecklistCognitiveErrors:content.line15")}
                        </p>
                        <p>
                          7.{" "}
                          <span className="redText">
                            {" "}
                            {t(
                              "partOneChecklistCognitiveErrors:content.line16"
                            )}
                          </span>{" "}
                          {t("partOneChecklistCognitiveErrors:content.line17")}
                        </p>
                        <p>
                          8.{" "}
                          <span className="redText">
                            {" "}
                            {t(
                              "partOneChecklistCognitiveErrors:content.line18"
                            )}
                          </span>{" "}
                          {t("partOneChecklistCognitiveErrors:content.line19")}
                        </p>
                        <p>
                          9.{" "}
                          <span className="redText">
                            {t(
                              "partOneChecklistCognitiveErrors:content.line20"
                            )}
                          </span>{" "}
                          {t("partOneChecklistCognitiveErrors:content.line21")}
                        </p>
                        <p>
                          10.{" "}
                          <span className="redText">
                            {t(
                              "partOneChecklistCognitiveErrors:content.line22"
                            )}
                          </span>{" "}
                          {t("partOneChecklistCognitiveErrors:content.line23")}
                        </p>
                      </div>
                      <div>
                        <p className="redText">
                          {t("partOneChecklistCognitiveErrors:content.line24")}
                        </p>
                        {responseErrorMessage ? (
                          <div className="container mt-5">
                            <div className="row justify-content-center">
                              <div
                                className="alert alert-danger text-center"
                                role="alert"
                              >
                                {responseErrorMessage}
                              </div>
                            </div>
                          </div>
                        ) : checklistErrorsResponse !== undefined &&
                          checklistErrorsResponse.length !== 0 ? (
                          <div>
                            <div className="thoughts">
                              <p className="redText">
                                {t(
                                  "partOneChecklistCognitiveErrors:content.line25"
                                )}
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[0]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions1}
                                onChange={(e) => {
                                  selectHandler(
                                    "one",
                                    e.value,
                                    "I ate all this dinner, I am such a pig!",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.one,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line26"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[1]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions2}
                                onChange={(e) => {
                                  selectHandler(
                                    "two",
                                    e.value,
                                    "I only got this job because there were so few applicants.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.two,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line27"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[2]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions3}
                                onChange={(e) => {
                                  selectHandler(
                                    "three",
                                    e.value,
                                    "Frank doesn't like me, I know it because I feel it when I try to talk to him.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.three,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line28"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[3]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions4}
                                onChange={(e) => {
                                  selectHandler(
                                    "four",
                                    e.value,
                                    "All people who work at the supermarket are rude.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.four,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line29"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[4]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions5}
                                onChange={(e) => {
                                  selectHandler(
                                    "five",
                                    e.value,
                                    "These people think I am an idiot because I had such difficulty ordering my food.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.five,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line30"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[5]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions6}
                                onChange={(e) => {
                                  selectHandler(
                                    "six",
                                    e.value,
                                    "If I miss one day at the gym, my training plan is ruined.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.six,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line31"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[6]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions7}
                                onChange={(e) => {
                                  selectHandler(
                                    "seven",
                                    e.value,
                                    "These people only like me because I know Kristy. They wouldn't be so interested in me if I didn't know her.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.seven,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line32"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[7]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions8}
                                onChange={(e) => {
                                  selectHandler(
                                    "eight",
                                    e.value,
                                    "I am going to make a fool of myself at the party.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.eight,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line33"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[8]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions9}
                                onChange={(e) => {
                                  selectHandler(
                                    "nine",
                                    e.value,
                                    "If I had just been more supportive of my friend, she wouldn't have got into all this trouble.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.nine,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line34"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[9]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions10}
                                onChange={(e) => {
                                  selectHandler(
                                    "ten",
                                    e.value,
                                    "I don't fit in with the crowd at the yoga club. I am an unlikeable person.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.ten,
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="thoughts">
                              <p>
                                <span className="redText">
                                  {t(
                                    "partOneChecklistCognitiveErrors:content.line35"
                                  )}
                                </span>
                              </p>
                              <Select
                                placeholder={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? t(
                                        "partOneChecklistCognitiveErrors:placeholders"
                                      )
                                    : checklistErrorsResponse.array[10]
                                        .userResponce
                                }
                                isDisabled={
                                  checklistErrorsResponse.global_disable_status ===
                                  false
                                    ? false
                                    : true
                                }
                                options={selectOptions11}
                                onChange={(e) => {
                                  selectHandler(
                                    "eleven",
                                    e.value,
                                    "I have to look fantastic all of the time.",
                                    e.label
                                  );
                                }}
                              />
                              <p className="selected-txt-sty">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: options.eleven,
                                  }}
                                ></span>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="loader-icon">
                            <TailSpin color="#4f5962" height={50} width={50} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div>
                                        <FooterOfPrint />
                                    </div> */}
                </div>
                <div className="row">
                  <div className="previous1 col-6">
                    <Button
                      className="user-reponse-submit btn btn-primary link-sty"
                      onClick={previousPage}
                    >
                      {t("actions:previous")}
                    </Button>
                  </div>
                  <div className="next col-6">
                    {checklistErrorsResponse !== undefined &&
                    checklistErrorsResponse.length !== 0 &&
                    checklistErrorsResponse.global_disable_status === false ? (
                      <>
                        <span
                          data-tip={
                            disabled === true
                              ? t('partOneChecklistCognitiveErrors:toolTipText')
                              : ""
                          }
                          data-for="toolTip"
                        >
                          <Button
                            className="link-sty mb-2"
                            onClick={postSubmission}
                            disabled={disabled}
                          >
                            {t("actions:continue")}
                          </Button>
                        </span>
                        <ReactTooltip id="toolTip" />
                      </>
                    ) : (
                      <>
                        <Button
                          className="user-reponse-submit"
                          onClick={nextPage}
                        >
                          {t("actions:continue")}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 dashboard-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChecklistCognitiveErrors;
